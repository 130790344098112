import React from "react";

export interface GeneralStaticItemValue {
    name: string;
    colour: string;
    count: number;
    total: number;
}

export interface StatItemsListProps {
    items: GeneralStaticItemValue[];
}

export default function StatItemsList(props: StatItemsListProps) {
    return (
        <ul className="dash-stat-list">
            {props.items.map((item) => {
                const percentage = Math.round((item.count / item.total) * 100);

                return (
                    <li key={item.name}>
                        <p className="stat-label">
                            <span
                                className="stat-button"
                                style={{backgroundColor: item.colour}}
                            ></span>
                            {item.name}
                        </p>
                        <p className="stat-value">
                            {percentage}%{" "}
                            <span className="stat-breakdown">
                                ({item.count} / {item.total})
                            </span>
                        </p>
                    </li>
                );
            })}
        </ul>
    );
}
