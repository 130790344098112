import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";
import DatePickerInputButton from "../../Button/DatePickerInputButton";
import {getStaffWageReportFromService} from "../../../store/grs/staffWageReport/actions/StaffWageReportActions";
import {RootStore} from "../../../store/Store";
import WageCalculationTableWrapper from "./Components/WageCalculationTableWrapper";
import {WithServiceState} from "store-fetch-wrappers";
import {useUserData} from "../../Hooks/useUserData";
import {getNavItemsForUser} from "../Dashboard/Helpers/dashboardHelpers";
import useMcConfig from "../../Hooks/useMcConfig";
import {AccessDetailType, doesUserHaveAdminAccess} from "../../../utils/userDataUtils";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(WageCalculationTableWrapper);

const WageCalculation = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<Moment>(moment());
    const wageReportStore = useSelector((state: RootStore) => state.staffWageReport);
    const user = useUserData();
    const {config} = useMcConfig();
    useEffect(() => {
        if (!user.username.length) return;
        if (!config.auth) return;

        //Set Nav Items
        dispatch(setNavigationItems(getNavItemsForUser(user, config)));
    }, [user, config]);
    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    useEffect(() => {
        if (!user.username.length) return;
        if (!doesUserHaveAdminAccess(AccessDetailType.grs, user)) return;

        const startDateInclusive = startDate.clone().startOf("month").unix();
        const endDateExclusive = startDate.clone().endOf("month").unix();

        dispatch(
            getStaffWageReportFromService({
                startDateInclusive,
                endDateExclusive
            })
        );
    }, [startDate, user]);

    return (
        <div>
            <TopBar titleText="Wage Calculation" />
            <div className="page-container">
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col d-flex pr-0 justify-content-end">
                        <div className="width-250px">
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                dateFormat="MMMM yyyy"
                                showMonthYearPicker
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        </div>
                    </div>
                </div>
                <ServiceWrapper
                    {...wageReportStore}
                    showLoadingText={true}
                    loaderType={"overlay"}
                    placeholderBlockCount={1}
                    loaderWheelType={"three-ring"}
                />
            </div>
        </div>
    );
};

export default WageCalculation;
