import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useUserData} from "../../Hooks/useUserData";
import {getNavItemsForUser} from "./Helpers/dashboardHelpers";
import BackgroundContainer from "../../Containers/BackgroundContainer";
import {StaffAccessLevel} from "../../../api/staff";
import StaffCalendar from "./Components/StaffCalendar/StaffCalendar";
import useMcConfig from "../../Hooks/useMcConfig";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
import LiveIncidentTracker from "./Components/LiveIncidentTracker/LiveIncidentTracker";
import DrugStatsWidget from "./Components/Widgets/DrugStatsWidget";
import AbsencesWidget from "./Components/Widgets/AbsencesWidget";
import ComplianceWidget from "./Components/Widgets/ComplianceWidget";
import RefreshButton from "./Components/LiveIncidentTracker/Components/RefreshButton";
import {
    getAbsenceStatsForDashboard,
    nullifyAbsenceStatsStore
} from "../../../store/statistics/absence/absenceStats/actions/AbsenceStatsActions";
import {fetchStaffDash} from "../../../store/staff/dash/actions/StaffDashActions";

export function StaffDashboard() {
    return (
        <div className="dash-widgets-panel">
            <BackgroundContainer background={"background-clear"} addPageContainer>
                <StaffCalendar />
            </BackgroundContainer>
        </div>
    );
}

export function AdminDashboard() {
    const dispatch = useDispatch();

    // Load in all data
    useEffect(() => {
        dispatch(getAbsenceStatsForDashboard());
        dispatch(fetchStaffDash());

        return function () {
            dispatch(nullifyAbsenceStatsStore());
        };
    }, [dispatch]);

    return (
        <div className="dash-widgets-panel">
            <div className="dash-widgets">
                <div className="dash-widget drug_stats">
                    <DrugStatsWidget />
                </div>
                <div className="dash-widget absences">
                    <AbsencesWidget />
                </div>
                <div className="dash-widget compliance">
                    <ComplianceWidget />
                </div>
            </div>
        </div>
    );
}

const Dashboard = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const {config} = useMcConfig();

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        //Set Nav Items
        dispatch(setNavigationItems(getNavItemsForUser(user, config)));
    }, [user, config]);

    return (
        <div className="dash-container">
            <TopBar titleText="Medicare EMS Dashboard" />
            <div className="dash-content">
                {user.accessLevel === StaffAccessLevel.SystemAdministrator ? (
                    <AdminDashboard />
                ) : (
                    <StaffDashboard />
                )}

                <div className="dash-notifications">
                    <h2>
                        Live Notifications <RefreshButton />
                    </h2>
                    <LiveIncidentTracker />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
