import {Dispatch} from "redux";
import {ABSENCE_STATS_STORE, AbsenceStatsDispatchTypes} from "./AbsenceStatsActionTypes";
import {postDataToServiceWithData} from "store-fetch-wrappers";
import StaffApiModel from "../../../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../../../helpers/storeHelpers";
import {generateBlankAbsenceStatsBlock} from "../reducer/AbsenceStatsReducer";
import {showErrorToast} from "../../../../../utils/toastUtils";

export function nullifyAbsenceStatsStore() {
    return async function (dispatch: Dispatch<AbsenceStatsDispatchTypes>) {
        dispatch({
            type: ABSENCE_STATS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: generateBlankAbsenceStatsBlock()
        });
    };
}

export function getAbsenceStatsForDashboard() {
    return async function (dispatch: Dispatch<AbsenceStatsDispatchTypes>) {
        try {
            const data = await postDataToServiceWithData(
                ABSENCE_STATS_STORE,
                dispatch,
                () => StaffApiModel.getAbsenceApi().getAbsenceDashStats(),
                statusCodeCallback
            );

            if (!data) {
                showErrorToast("Could not fetch stats from service.");
                return;
            }

            dispatch({
                type: ABSENCE_STATS_STORE.SUCCESS,
                loading: false,
                error: null,
                data: data
            });
        } catch (e: any) {
            dispatch({
                type: ABSENCE_STATS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}
