import React, {useEffect, useState} from "react";
import {
    NotificationItem,
    SubSystem
} from "../../../../../../store/notificationsHub/actions/NotificationsHubActionTypes";
import {toArray} from "../../../../../../utils/sortingUtils";
import GRSNotificationListItem from "./GRSNotificationListItem";
import NotificationListItem from "./NotificationListItem";

/** Wrapper that sits Notifications Items. Sorts the items based on Subsystems*/
const NotificationListItemWrapper = (props: NotificationItem[]) => {
    const [notifications, setNotifications] = useState<NotificationItem[]>(toArray(props));

    useEffect(() => {
        setNotifications(toArray(props));
    }, [props]);

    return (
        <React.Fragment>
            <div className="notification-item-wrapper">
                {notifications.length > 0 &&
                    notifications.map((el: NotificationItem, index: number) => {
                        if (el.subSystem === SubSystem.GRS) {
                            return <GRSNotificationListItem {...el} key={index} />;
                        }

                        return (
                            <>
                                <NotificationListItem {...el} key={index} />
                                <div className="notification-item-spacer" />
                            </>
                        );
                    })}
                {notifications.length === 0 && (
                    <h6 className="text-center pt-3">
                        <b>There are no notifications at this time...</b>
                    </h6>
                )}
            </div>
        </React.Fragment>
    );
};

export default NotificationListItemWrapper;
