import React from "react";
import WidgetHeader from "./WidgetHeader";
import {useComplianceData} from "../../../../../store/statistics/prf/hooks/useComplianceData";
import StatItemsList, {GeneralStaticItemValue} from "./StatItemsList";
import {Chart, ChartWrapperOptions} from "react-google-charts";
import useIsMobile from "../../../../../hooks/useIsMobile";

type GoogleChartValue = [
    string,
    string | number,
    Record<string, string> | string,
    Record<string, string> | string
];

export default function ComplianceWidget() {
    const isMobile = useIsMobile(500);
    const complianceData = useComplianceData();
    const chartWidth = isMobile ? "300px" : "440px";
    const data: GoogleChartValue[] = [
        ["Compliance Type", "Percentage", {role: "annotation", type: "string"}, {role: "style"}]
    ];
    const prfColours: Record<string, string> = {
        "PRF 100%": "#64c246",
        "PRF 90-99%": "#FED545",
        "PRF <90%": "#fb8518"
    };
    const statItems: GeneralStaticItemValue[] = [];
    const options: ChartWrapperOptions["options"] = {
        annotations: {
            alwaysOutside: true
        },
        legend: "none",
        fontSize: 14,
        fontName: "Inter"
    };

    if (complianceData.prf) {
        for (const prfValue of complianceData.prf) {
            const prfColour = prfColours[prfValue.outerLabel] ?? "#fb8518";

            data.push([
                prfValue.outerLabel,
                prfValue.compliancePercentage,
                `${prfValue.compliancePercentage}%`,
                prfColour
            ]);
            statItems.push({
                name: prfValue.outerLabel,
                colour: prfColour,
                count: prfValue.actualValue ?? 0,
                total: prfValue.totalValue
            });
        }
    }

    if (complianceData.policySuite) {
        const policyColour = "#d945fe";
        data.push([
            complianceData.policySuite.outerLabel,
            complianceData.policySuite.compliancePercentage,
            `${complianceData.policySuite.compliancePercentage}%`,
            policyColour
        ]);
        statItems.push({
            name: complianceData.policySuite.outerLabel,
            colour: policyColour,
            count: complianceData.policySuite.actualValue ?? 0,
            total: complianceData.policySuite.totalValue
        });
    }

    return (
        <>
            <WidgetHeader name="Overall Compliance" link="https://prf.medicare-ems.co.uk" />
            {statItems.length > 0 && (
                <div className="dash-centre">
                    <Chart
                        chartType="ColumnChart"
                        width={chartWidth}
                        height="100%"
                        data={data}
                        options={options}
                    />
                </div>
            )}
            <StatItemsList items={statItems} />
        </>
    );
}
