import React from "react";
import {NotificationItem} from "../../../../../../store/notificationsHub/actions/NotificationsHubActionTypes";
import {FaTriangleExclamation} from "react-icons/fa6";

const NotificationListItem = (props: NotificationItem) => {
    return (
        <React.Fragment>
            <div className="notification-item">
                <div className="notification-item-icon-outer">
                    <FaTriangleExclamation className="notification-item-icon" />
                </div>

                <div className="notification-item-content">
                    <h3>
                        {props.title}
                        {props.link && (
                            <p className="notification-item-link">
                                <a href={props.link} target="_blank" rel="noreferrer">
                                    View more
                                </a>
                            </p>
                        )}
                    </h3>
                    <p>{props.actionText}</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotificationListItem;
