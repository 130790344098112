import {useLocation} from "react-router-dom";
import {useEffect} from "react";

/**
 * Set the <title> tag to the given title.
 * @param title Title to set
 */
export default function PageTitleTag({title}: {title: string}) {
    const location = useLocation();

    useEffect(() => {
        document.title = title + " | Medicare";
    }, [location, title]);

    return null;
}
