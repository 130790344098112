import {useEffect, useState} from "react";

export default function useIsMobile(mobileWidth = 768) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileWidth);

    useEffect(() => {
        const onResize = () => {
            setIsMobile(window.innerWidth <= mobileWidth);
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [mobileWidth]);

    return isMobile;
}
