import {createDefaultStoreState, createReducer, StoreServiceData} from "store-fetch-wrappers/dist";
import {DashboardStats} from "../../../../api/staff";
import {STAFF_DASH_STORE, StaffDashDispatchTypes} from "../actions/StaffDashActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DashboardStats>(createEmptyDashboardStats());

const staffDashReducer = (
    state = defaultState,
    action: StaffDashDispatchTypes
): StoreServiceData<DashboardStats> =>
    createReducer(state, action, STAFF_DASH_STORE, () => showErrorToast(action.error));

export default staffDashReducer;

function createEmptyDashboardStats(): DashboardStats {
    return {
        totalUsers: {
            total: 0,
            superAdmins: 0
        },
        usersByAccessLevel: [],
        usersByRegion: [],
        usersByClinicalGrade: [],
        usersByMfaCount: {
            sms: 0,
            totp: 0
        }
    };
}
