import React from "react";
import WidgetHeader from "./WidgetHeader";
import {Chart, ChartWrapperOptions} from "react-google-charts";
import StatItemsList, {GeneralStaticItemValue} from "./StatItemsList";
import useIsMobile from "../../../../../hooks/useIsMobile";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {AbsenceType} from "../../../../../api/staff";

type GoogleChartValue = [string, string | number];

function getShortAbsenceTypeName(type: AbsenceType): string {
    switch (type) {
        case AbsenceType.Unauthorised:
            return "Unauthorised";
        case AbsenceType.Holiday:
            return "Leave";
        case AbsenceType.SickPaid:
            return "Sick Paid";
        case AbsenceType.SickUnpaid:
            return "Sick Unpaid";
    }
}

function getAbsenceTypeColour(type: AbsenceType): string {
    switch (type) {
        case AbsenceType.Unauthorised:
            return "#fb1818";
        case AbsenceType.Holiday:
            return "#64c246";
        case AbsenceType.SickUnpaid:
            return "#18c4fb";
        case AbsenceType.SickPaid:
            return "#d945fe";
    }
}

function getOptions(isMobile: boolean, colours: string[]): ChartWrapperOptions["options"] {
    if (isMobile) {
        return {
            pieHole: 0.4,
            legend: "none",
            fontSize: 14,
            fontName: "Inter",
            colors: colours,
            chartArea: {width: "100%", height: "100%"}
        };
    }

    return {
        pieHole: 0.5,
        pieSliceText: "none",
        legend: "none",
        fontSize: 14,
        fontName: "Inter",
        chartArea: {width: "100%", height: "93%"},
        colors: colours
    };
}

function getAbsenceStatItem(
    type: AbsenceType,
    count: number | null | undefined,
    totalStaff: number
): GeneralStaticItemValue {
    return {
        name: getShortAbsenceTypeName(type),
        colour: getAbsenceTypeColour(type),
        count: count ?? 0,
        total: totalStaff
    };
}

export default function AbsencesWidget() {
    const isMobile = useIsMobile(500);
    const absenceStatsStore = useSelector((state: RootStore) => state.absenceStats);
    const staffDashStore = useSelector((state: RootStore) => state.staffDash);
    const absences = absenceStatsStore.data;
    const chartWidth = isMobile ? "300px" : "350px";
    const data: GoogleChartValue[] = [["Absence Type", "Number of Absences"]];
    const colours: string[] = [];
    const options = getOptions(isMobile, colours);
    const totalUsers = staffDashStore.data?.totalUsers?.total ?? 0;
    const statItems: GeneralStaticItemValue[] = [
        getAbsenceStatItem(AbsenceType.Holiday, absences?.absenceCounts?.Holiday, totalUsers),
        getAbsenceStatItem(AbsenceType.SickPaid, absences?.absenceCounts?.SickPaid, totalUsers),
        getAbsenceStatItem(AbsenceType.SickUnpaid, absences?.absenceCounts?.SickUnpaid, totalUsers),
        getAbsenceStatItem(
            AbsenceType.Unauthorised,
            absences?.absenceCounts?.Unauthorised,
            totalUsers
        )
    ];

    for (const item of statItems) {
        data.push([item.name, item.count]);
        colours.push(item.colour);
    }

    return (
        <>
            <WidgetHeader name="Absence Tracker" link="https://absence.medicare-ems.co.uk" />
            <div className="dash-centre">
                <Chart chartType={"PieChart"} data={data} options={options} width={chartWidth} />
            </div>
            <StatItemsList items={statItems} />
        </>
    );
}
