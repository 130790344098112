import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {useUserData} from "../../../../Hooks/useUserData";
import {fetchNotificationsForUser} from "../../../../../store/notificationsHub/actions/NotificationsHubActions";
import {useInterval} from "../../../../Hooks/useInterval";
import {WithServiceState} from "store-fetch-wrappers";
import NotificationListItemWrapper from "./Components/NotificationListItemWrapper";
import {SubSystem} from "../../../../../store/notificationsHub/actions/NotificationsHubActionTypes";
import useMcConfig from "../../../../Hooks/useMcConfig";

const WrappedNotifications = WithServiceState(NotificationListItemWrapper);

/** Incident tracker to track all issues within the MC modules */
const LiveIncidentTracker = () => {
    const notificationsHubStore = useSelector((state: RootStore) => state.notificationsHub);
    const user = useUserData();
    const dispatch = useDispatch();
    const {config} = useMcConfig();
    const subSystem = SubSystem.IM;

    /** Refreshes data for notifications every 5 minutes */
    useInterval(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        getLiveIncidents();
    }, 5); // 5-Minute Delay. Hook has conversion within it.

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        if (!subSystem) return;

        getLiveIncidents();
    }, [user, subSystem, config]);

    /** Gets the live incidents for the incident tracker */
    const getLiveIncidents = () => {
        dispatch(fetchNotificationsForUser(user, config, SubSystem.IM));
    };

    return (
        <React.Fragment>
            <WrappedNotifications
                {...notificationsHubStore}
                showLoadingText={true}
                loaderType={"normal"}
                placeholderBlockCount={1}
                loaderWheelType={"three-ring"}
            />
        </React.Fragment>
    );
};

export default LiveIncidentTracker;
