import {AuditTypeStats, PrfStatsBase} from "../../../../api/prf";
import {StatisticsItem} from "../../helpers/statisticsHelpers";
import {
    getAngleForHalfPieChartFromPercentage,
    getFlooredPercentage
} from "../../../../utils/mathUtils";

/** Generate the overall compliance of the system */
export function generateOverallAuditComplianceStats(
    auditStats: AuditTypeStats[]
): StatisticsItem[] {
    const prfStatBase = getBlankPrfStatsItem();

    for (const stat of auditStats) {
        prfStatBase.totalNumberOfAudits += stat.totalNumberOfAudits;
        prfStatBase.numberOfAudits100PercentCompliant += stat.numberOfAudits100PercentCompliant;
        prfStatBase.numberOfAudits90to99PercentCompliant +=
            stat.numberOfAudits90to99PercentCompliant;
        prfStatBase.numberOfAuditsBelow90PercentCompliant +=
            stat.numberOfAuditsBelow90PercentCompliant;
    }

    return getStatisticsItemFromPrfStats(prfStatBase);
}

export function getStatisticsItemFromPrfStats(stats: PrfStatsBase): StatisticsItem[] {
    const {totalNumberOfAudits} = stats;
    if (totalNumberOfAudits === 0) {
        return [defaultPrfStats];
    }

    return [
        getPrfStatsItem(stats.totalNumberOfAudits, stats.numberOfAudits100PercentCompliant, "100%"),
        getPrfStatsItem(
            stats.totalNumberOfAudits,
            stats.numberOfAudits90to99PercentCompliant,
            "90-99%"
        ),
        getPrfStatsItem(
            stats.totalNumberOfAudits,
            stats.numberOfAuditsBelow90PercentCompliant,
            "<90%"
        )
    ];
}

function getPrfStatsItem(totalNumber: number, matchCount: number, name: string): StatisticsItem {
    const percentage = getFlooredPercentage(matchCount, totalNumber);

    return {
        totalValue: totalNumber,
        actualValue: matchCount,
        tooltipText: `PRF ${name} Compliance: ${percentage}%`,
        outerLabel: `PRF ${name}`,
        compliancePercentage: percentage,
        needleAngle: getAngleForHalfPieChartFromPercentage(percentage)
    };
}

function getBlankPrfStatsItem(): PrfStatsBase {
    return {
        totalNumberOfAudits: 0,
        numberOfAudits100PercentCompliant: 0,
        numberOfAudits90to99PercentCompliant: 0,
        numberOfAuditsBelow90PercentCompliant: 0
    };
}

const defaultPrfStats: StatisticsItem = {
    needleAngle: 0,
    compliancePercentage: 0,
    totalValue: 0,
    outerLabel: "PRF",
    tooltipText: "Overall PRF Audit Compliance: 0%"
};
