import {useUserData} from "../../../../components/Hooks/useUserData";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AccessDetailType, doesUserHaveAdminAccess} from "../../../../utils/userDataUtils";
import {
    getOverallPrfStats,
    nullifyOverallComplianceStore
} from "../overallCompliance/actions/OverallComplianceActions";
import {
    fetchViewCountForCategoryStats,
    nullifyViewCountStore
} from "../../ppb/documentViewStats/actions/DocumentViewStatsActions";
import {useEffect} from "react";
import {StatisticsItem} from "../../helpers/statisticsHelpers";

export interface ComplianceData {
    prf?: StatisticsItem[];
    policySuite?: StatisticsItem;
}

export function useComplianceData(): ComplianceData {
    const user = useUserData();
    const dispatch = useDispatch();
    const prfStore = useSelector((state: RootStore) => state.overallCompliance);
    const prfItems = prfStore.data || [];
    const policyStore = useSelector((state: RootStore) => state.documentViewStats);
    const policySuite = policyStore.data ? policyStore.data : undefined;
    const refreshStats = () => {
        if (doesUserHaveAdminAccess(AccessDetailType.prf, user)) {
            dispatch(getOverallPrfStats());
        }
        if (doesUserHaveAdminAccess(AccessDetailType.ppb, user)) {
            dispatch(fetchViewCountForCategoryStats());
        }
    };

    useEffect(() => {
        return () => {
            dispatch(nullifyOverallComplianceStore());
            dispatch(nullifyViewCountStore());
        };
    }, []);

    useEffect(() => {
        if (!user.username.length) return;
        refreshStats();
    }, [user]);

    if (!doesUserHaveAdminAccess(AccessDetailType.prf, user)) return {};

    return {
        prf: prfItems,
        policySuite
    };
}
