import {Dispatch} from "redux";
import {STAFF_DASH_STORE, StaffDashDispatchTypes} from "./StaffDashActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import StaffApiModel from "../../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../../helpers/storeHelpers";
import store from "../../../Store";

export const fetchStaffDash = () => {
    return async (dispatch: Dispatch<StaffDashDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                STAFF_DASH_STORE,
                dispatch,
                () => StaffApiModel.getUsersApi().getDashboardStats(),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().drugBatchList.data;

                if (!data) return [];

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: STAFF_DASH_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
