import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import staffWageReportReducer from "./grs/staffWageReport/reducer/StaffWageReportReducer";
import notificationsHubReducer from "./notificationsHub/reducer/NotificationsHubReducer";
import overallComplianceReducer from "./statistics/prf/overallCompliance/reducer/OverallComplianceReducer";
import documentViewStatsReducer from "./statistics/ppb/documentViewStats/reducer/DocumentViewStatsReducer";
import drugBatchListStatsReducer from "./statistics/mm/drugBatchList/reducer/DrugBatchListStatsReducer";
import calendarEntryListReducer from "./grs/calendarEntryList/reducer/CalendarEntryListReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import absenceStatsReducer from "./statistics/absence/absenceStats/reducer/AbsenceStatsReducer";
import drugBatchListReducer from "./mm/drugBatchList/reducer/DrugBatchListReducer";
import totpReducer from "./totp/reducer/TotpReducer";
import staffDashReducer from "./staff/dash/reducer/StaffDashReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    staffWageReport: staffWageReportReducer,
    notificationsHub: notificationsHubReducer,
    overallCompliance: overallComplianceReducer,
    documentViewStats: documentViewStatsReducer,
    drugBatchListStats: drugBatchListStatsReducer,
    calendarEntryList: calendarEntryListReducer,
    absenceStats: absenceStatsReducer,
    drugBatchList: drugBatchListReducer,
    totp: totpReducer,
    staffDash: staffDashReducer
});

export default RootReducer;
