import React from "react";

export interface WidgetHeaderProps {
    name: string;
    link: string;
}

export default function WidgetHeader(props: WidgetHeaderProps) {
    return (
        <div className="dash-widget-header">
            <h2>{props.name}</h2>
            <a href={props.link}>View more</a>
        </div>
    );
}
