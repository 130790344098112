import {DashboardStats} from "../../../../api/staff";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const STAFF_DASH_STORE = createStoreState("staff_dash");

interface StaffDashLoading extends StoreServiceData<DashboardStats> {
    type: typeof STAFF_DASH_STORE.LOADING;
}
interface StaffDashError extends StoreServiceData<DashboardStats> {
    type: typeof STAFF_DASH_STORE.ERROR;
}
interface StaffDashSuccess extends StoreServiceData<DashboardStats> {
    type: typeof STAFF_DASH_STORE.SUCCESS;
}

export type StaffDashDispatchTypes = StaffDashSuccess | StaffDashError | StaffDashLoading;
