import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {StatisticsItem} from "../../../helpers/statisticsHelpers";

export const OVERALL_COMPLIANCE_STORE = createStoreState("overall_compliance");

interface OverallComplianceLoading extends StoreServiceData<StatisticsItem[]> {
    type: typeof OVERALL_COMPLIANCE_STORE.LOADING;
}

interface OverallComplianceError extends StoreServiceData<StatisticsItem[]> {
    type: typeof OVERALL_COMPLIANCE_STORE.ERROR;
}

interface OverallComplianceSuccess extends StoreServiceData<StatisticsItem[]> {
    type: typeof OVERALL_COMPLIANCE_STORE.SUCCESS;
}

export type OverallComplianceDispatchTypes =
    | OverallComplianceLoading
    | OverallComplianceError
    | OverallComplianceSuccess;
