import React from "react";
import {SubSystem} from "../../../../../../store/notificationsHub/actions/NotificationsHubActionTypes";
import useMcConfig from "../../../../../Hooks/useMcConfig";
import {useUserData} from "../../../../../Hooks/useUserData";
import {fetchNotificationsForUser} from "../../../../../../store/notificationsHub/actions/NotificationsHubActions";
import {useDispatch} from "react-redux";
import Tooltip from "../../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../../Icon/Icon";

export default function RefreshButton() {
    const dispatch = useDispatch();
    const user = useUserData();
    const {config} = useMcConfig();

    const getLiveIncidents = () => {
        dispatch(fetchNotificationsForUser(user, config, SubSystem.IM));
    };

    return (
        <Tooltip
            tooltipText={"Refresh Live Reporting Tracker"}
            wrapperClassName="cursor-pointer"
            size={"lg"}
            place={"left"}
            theme={"dark"}
        >
            <Icon
                className="mr-3 tracker-icon-button"
                rootElement={"Span"}
                icon={IconType.Refresh_Square}
                size={"Medium"}
                onClick={() => getLiveIncidents()}
            />
        </Tooltip>
    );
}
